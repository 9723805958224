:root {
  font-family: 'Quantico', sans-serif;
  color: white;
}

html {
  height: 100%;
  /* background-color: lightcoral; */
  background-color: rgb(9, 27, 36);
}

body {
  margin: 0;
  min-height: 100%;
}

.homeTopBackground {
  margin: 0;
  padding: 0;
  background-image: url('./images/backgroundportfolio.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: cover;
  min-height: 840px;
  border-top: 1px rgb(19, 99, 19) solid;
  /* box-shadow-top: 10px 5px 5px red; */
  border-bottom: thin grey solid;
  position: relative;
}

.titleInfo {
  position: absolute;
  bottom: 100px;
  right: 80px;
}

div .projects {
  margin: 0;
  padding: 0;
}

.topNav {
  display: flex;
  justify-content: flex-end;
  /* border: solid blue 2px; */
  align-items: center;
  list-style: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 25rem;
}

.navLink {
  text-decoration: none;
  color: white;
  text-transform: uppercase;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 18px;
}

.navLink:hover {
  color: rgb(10, 197, 172);
}

.navLink:active {
  color: rgb(9, 117, 103);
}

p {
  line-height: 0.4;
}

.name {
  font-family: 'Quantico', sans-serif;
  margin: 0;
  padding: 0;
  font-size: 70px;
}

.title {
  font-family: 'Quantico', sans-serif;
  font-size: 37px;
  margin-left: 1px;
}

.projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projectsTable {
  /* border: solid orange 2px; */
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin-bottom: 5rem;
}

@media (max-width: 425px) {
  .titleInfo h1 {
    font-size: 56px;
  }
  .titleInfo p {
    font-size: 30px;
    line-height: 1;
  }

  .skillsBox ul {
    margin-bottom: 2rem;
  }

  .skillsColumn p {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 10px;
    line-height: 1;
  }

  .skillsColumn li {
    padding: 0;
    font-size: 14px;
  }

  li {
    padding: 0;
  }

  .navLink {
    font-size: 10px;
    padding: 0;
    /* margin: 0; */
  }

  .aboutSection {
    display: flex;
    width: 100%;
  }
}

@media (max-width: 811px) {
  li {
    text-align: center;
    list-style: none;
    /* margin-left: 0; */
  }

  .skillsColumn p {
    margin-right: 0;
  }
}

@media (max-width: 1000px) {
  .aboutSection {
    flex-direction: column;
    align-items: center;
  }

  .meImg {
    width: 250px;
  }
}

@media (min-width: 1500px) {
  .aboutMeText {
    width: 40%;
  }
}

.name {
  line-height: 1;
  font-size: 80px;
}

@media (max-width: 601px) {
  li {
    list-style: none;
  }

  .skillsColumn {
    align-items: center;
  }

  .skillsBox div {
    margin: 0;
    padding: 0;
  }
}

.resumeContainer {
  margin-top: 10rem;
  display: flex;
  justify-content: center;
  padding-top: 5rem;
}

@media (max-width: 1150px) {
  .resumePaddingContainer div {
    padding-top: 15rem;
  }
}

@media (max-width: 900px) {
  .resumePaddingContainer div {
    padding-top: 20rem;
  }
}

@media (max-width: 760px) {
  .resumePaddingContainer div {
    padding-top: 25rem;
  }
}

@media (max-width: 650px) {
  .resumePaddingContainer div {
    padding-top: 35rem;
  }
}

@media (max-width: 575px) {
  .resumePaddingContainer div {
    padding-top: 40rem;
  }
}

@media (max-width: 520px) {
  .resumePaddingContainer div {
    padding-top: 50rem;
  }
}

@media (max-width: 450px) {
  .resumePaddingContainer div {
    padding-top: 50rem;
  }
}



@media (max-width: 360px) {
  .resumePaddingContainer div {
    padding-top: 50rem;
  }
}

@media (max-width: 320px) {
  .resumePaddingContainer div {
    padding-top: 60rem;
  }
}

@media (max-width: 270px) {
  .resumePaddingContainer div {
    padding-top: 80rem;
  }
}

@media (max-width: 220px) {
  .resumePaddingContainer div {
    padding-top: 100rem;
  }
}

@media (max-width: 1200px) {
  .topNav {
    justify-content: center;
    margin: 0;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (max-width: 1400px) {
  .skillsBox {
    flex-direction: column;
  }

  .extraMargin {
    margin-right: 20px;
  }

  /* .skillsColumn {
    align-items: flex-start;
  } why wont this work???? */

  .skillTitle {
    /* display: flex;
    justify-content: flex-start; */
    /* text-align: left; */
    margin-top: 90px;
  }
}

.projectsTitle {
  font-size: 40px;
  /* border: solid pink 2px; */
}

.projBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  background-color: rgb(9, 53, 54);
  /* border: solid rgb(38, 75, 64); */
  border: thin grey solid;
  /* width: 50%; */
  border-radius: 2px;
  margin-right: 10px;
  margin-left: 10px;
  padding-top: 25px;
  font-family: sans-serif;
  margin-bottom: 10px;
  margin-top: 10px;
}

@media (max-width: 450px) {
  .projBox ul {
    width: 300px;
  }

  /* .projectDescription {
    width: 50%;
    display: flex;
    justify-content: center;
  } */
}

.projectBox {
  padding: 0;
  list-style: none;
  width: 400px;
  margin-left: 10px;
  /* border: solid pink 5px; */
}

.projectTitle {
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  color: rgba(255, 255, 255, 0.35);
  font-size: 20px;
  padding-bottom: 10px;
}

.skillsTable {
  /* border: solid orange 2px; */
  display: flex;
  margin-bottom: 5rem;
  width: 50%;
}

.skillsBox {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  text-align: center;
  background-color: rgb(9, 53, 54);
  /* border: solid white 2px; */
  border: thin grey solid;
  border-radius: 2px;
  margin-right: 2px;
  margin-left: 2px;
  padding-bottom: 50px;
  font-family: sans-serif;

  width: 100%;
  /* border: solid red 4px; */
}

.skillsColumn {
  display: flex;
  flex-direction: column;
  /* border: solid red 3px; */
  flex: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: flex-start;
}

.innerList {
  text-align: left;
  margin-left: -30px;
  list-style: disc;
}

.negativeMargin {
  margin-bottom: -2rem;
}

.extraSkillPadding {
  padding-bottom: 40px;
}

.skillTitle {
  font-size: 26px;
  font-family: 'Quantico', sans-serif;
  text-decoration: underline;
  font-weight: bold;
  margin-bottom: 25px;
  margin-right: 70px;
  margin-top: 40px;
  /* text-align: center; */
}

.extraMargin {
  margin-left: 60px;
}

.aboutMeDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: solid grey thin;
  border-bottom: solid grey thin;
  width: 100%;
  flex: 1;
  position: absolute;
  left: 0;
  right: 0;
  background-color: rgb(4, 14, 22);
  padding-bottom: 10rem;
  /* border: solid pink 2px; */
}

.aboutHeader {
  margin-top: 2rem;
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  /* border: solid orange 3px; */
  width: 100%;
  font-size: 40px;
}

.aboutMeText {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-left: 20px;
  line-height: 1;
  font-family: 'Anonymous Pro', monospace;
  font-size: 25px;
  /* border: solid pink 2px; */
  flex: 1;
  /* width: 40% */
}

.aboutSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  /* border: solid pink 2px; */
}

.meImg {
  /* border: solid grey 2px;
  border-radius: 5px;
  box-shadow: grey 2px 0 2px 2px; */
}

.contactSection {
  display: flex;
  justify-content: center;
  /* border: solid white 2px; */
  align-items: center;
}

#contactTarget {
  margin-top: 10rem;
  font-size: 40px;
}

.iconTable {
  display: flex;
  justify-content: center;
  padding: 0;
  margin-top: -10px;
}

.test {
  padding-top: 46rem;
  color: white;
  font-size: 20px;
}

.linkSeparator {
  margin-top: 20px;
}

.linkSeparator li {
  margin-right: 50px;
  margin-bottom: 5px;
  text-align: right;
  /* list-style: square; */
}

.projectDescription {
  margin-top: 10px;
  margin-bottom: 15px;
}

.projectLink {
  /* text-decoration: none; */
  color: rgba(255, 255, 255, 0.822);
  font-weight: bold;
  font-size: 20px;
  /* justify-content: center;
  text-align: center;
  display: flex; */
}

.projectLink:hover {
  color: rgba(255, 255, 255, 0.699);
  text-decoration: underline;
}

.projectLink:active {
  color: rgba(255, 255, 255, 0.499);
}

.resumeButton {
  font-size: 40px;
  color: white;
  text-decoration: none;
  border: solid white 2px;
  border-radius: 3px;
  padding: 5px 15px 5px 15px;
}

.resumeButton:hover {
  color: rgba(255, 255, 255, 0.699);
}

.resumeButton:active {
  color: rgba(255, 255, 255, 0.499);
}

/* test */

ul.tt-socail {
  padding: 0;
  padding-top: 10px;
  display: flex;
  align-items: center;
}

.tt-socail li {
  display: inline-block;
  margin-right: 10px;
}
.tt-socail a {
  display: block;
  width: 50px;
  height: 50px;
  font-size: 17px;
  line-height: 34px;
  color: #fff;
  text-align: center;
  border: 1px solid #fff;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.footerBox {
  margin-bottom: 2rem;
}

.fa:hover {
  opacity: 0.6;
}

.fa:active {
  opacity: 0.3;
}

.fa-mail {
  content: url('./images/mail.png');
  height: 18px;
  margin-bottom: -10px;
}

.fa-github {
  content: url('./images/octocat.png');
  height: 23px;
  margin-bottom: -14px;
}

.fa-linkedin {
  content: url('./images/li.png');
  height: 20px;
  margin-bottom: -10px;
}

.fa-medium {
  content: url('./images/medium.png');
  height: 22px;
  margin-bottom: -12px;
}

h5 {
  text-align: center;
  margin-left: -8px;
  margin-bottom: 10px;
}

@media (max-width: 1300px) {
  .projectsTable {
    flex-flow: row wrap;
    justify-content: center;
  }
  .homeTopBackground div {
    position: relative;
    margin-top: 15rem;
    margin-left: 175px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0;
    /* border: solid red 2px; */
    align-items: center;
  }
  .homeTopBackground p {
    line-height: 1;
  }
  .titleInfo h1 {
    /* border: solid blue 6px; */
  }

  .homeTopContainer div {
    min-height: 500px;
  }

  .aboutSection p {
    font-size: 22px;
    width: 95%;
    /* text-align: justify; */
  }
}
